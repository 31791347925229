import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Feature, FeatureCollection } from 'geojson';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FloorplanApiPaths } from 'app/configuration';

@Injectable({
    providedIn: 'root',
})
export class FloorplanService {
    private readonly httpClient = inject(HttpClient);
    private floorplanApiPaths: FloorplanApiPaths = inject(FloorplanApiPaths);
    private baseUrl = this.floorplanApiPaths.baseUrl;
    /* istanbul ignore next wvk, just simple get */
    public getZone(contextId: string, zoneId: string): Observable<Feature> {
        const url = `${this.baseUrl}/${contextId}/zones/${zoneId}`;
        return this.httpClient.get<Feature>(url);
    }

    /* istanbul ignore next wvk, just simple get */
    public getZoneZones(contextId: string, zoneId: string): Observable<FeatureCollection> {
        const url = `${this.baseUrl}/${contextId}/zones/${zoneId}/zones`;
        const params = new HttpParams().append('recursive', 'true');
        return this.httpClient.get<FeatureCollection>(url, { params });
    }

    /* istanbul ignore next wvk, just simple get */
    public getZoneElements(contextId: string, zoneId: string): Observable<FeatureCollection> {
        const url = `${this.baseUrl}/${contextId}/zones/${zoneId}/elements`;
        const params = new HttpParams().append('recursive', 'true');
        return this.httpClient.get<FeatureCollection>(url, { params });
    }

    /* istanbul ignore next wvk, just simple get */
    public getImage(imageUrl: string): Observable<Blob> {
        return this.httpClient
            .get<Blob>(imageUrl, { responseType: 'blob' as 'json', observe: 'body' })
            .pipe(map((part) => new Blob([part])));
    }
}
