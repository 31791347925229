import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { DaliDevice } from '@priva/next-model';

import { X_HEADER } from 'app/core/http';
import { DaliActions, DaliStateContainer } from 'app/solution/controllers/state/dali';
import { SolutionStateContainer } from 'app/solution/state';

import { NextLocalStorageService } from '../../local-storage/next-local-storage.service';
import { extractSolutionIdFromNextApi } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncDaliDevicesInterceptor implements HttpInterceptor {
    private readonly localStorageService = inject(NextLocalStorageService);
    private readonly store$ = inject<Store<SolutionStateContainer & DaliStateContainer>>(
        Store<SolutionStateContainer & DaliStateContainer>,
    );

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) === DaliActions.getDaliDevices.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            return this.store$.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(this.localStorageService.getDaliDevices(solutionId)).pipe(
                            map((daliDevices: DaliDevice[]) => new HttpResponse({ body: daliDevices })),
                        );
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }
        return next.handle(request);
    }
}
