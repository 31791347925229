export const extractSolutionIdFromNextApi = (url: string) => {
    const solutionIdMatcher = /solutions\/([a-z0-9-]*).*/;
    return (url.match(solutionIdMatcher) ?? ['', ''])[1];
};
export const extractElementIdFromNextApi = (url: string) => {
    const elementIdMatcher = /solutions\/.*\/hardware_registrations\/([a-z0-9-]*).*/;
    return (url.match(elementIdMatcher) ?? ['', ''])[1];
};
export const extractControllerPropertiesElementIdFromNextApi = (url: string) => {
    const elementIdMatcher = /solutions\/.*\/controller_properties\/([a-z0-9-]*).*/;
    return (url.match(elementIdMatcher) ?? ['', ''])[1];
};
export const extractControllerProtocolSettingsElementIdFromNextApi = (url: string) => {
    const elementIdMatcher = /solutions\/.*\/controller_protocol_settings\/([a-z0-9-]*).*/;
    return (url.match(elementIdMatcher) ?? ['', ''])[1];
};

export const extractLibraryIdFromNextApi = (url: string) => {
    // eslint-disable-next-line
    const libraryIdMatcher = /libraries\/([a-zA-Z\.0-9-]*)\/models/;
    return (url.match(libraryIdMatcher) ?? ['', ''])[1];
};

export const extractZoneIdFromNextApi = (url: string) => {
    const zoneIdMatcher = /topology\/zones\/([a-z0-9-]*)/;
    return (url.match(zoneIdMatcher) ?? ['', ''])[1];
};

export const extractRiomIdFromNextApi = (url: string) => {
    const riomIdSolutionsMatcher = /rioms\/([a-z0-9-]*)/;
    return (url.match(riomIdSolutionsMatcher) ?? ['', ''])[1];
};

export const extractZoneIdFromSolutionsNextApi = (url: string) => {
    const zoneIdSolutionsMatcher = /solutions\/.*\/zones\/([a-z0-9-]*)/;
    return (url.match(zoneIdSolutionsMatcher) ?? ['', ''])[1];
};

export const extractSolutionIdFromFloorplanApi = (url: string) => {
    const solutionIdMatcher = /solutions\/([a-z0-9-]*).*/;
    return (url.match(solutionIdMatcher) ?? ['', ''])[1];
};

export const extractZoneIdFromFloorplanApi = (url: string) => {
    const zoneIdMatcher = /solutions\/([a-z0-9-]*)\/zones\/([a-z0-9-]*).*/;
    return (url.match(zoneIdMatcher) ?? ['', '', ''])[2];
};

export const extractDeviceIdFromFloorplanApi = (url: string) => {
    const deviceIdMatcher = /solutions\/([a-z0-9-]*)\/elements\/([a-z0-9-]*).*/;
    return (url.match(deviceIdMatcher) ?? ['', '', ''])[2];
};
