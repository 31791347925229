import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    ControllerConfigurationInfo,
    ControllerProperties,
    ControllerProtocolSettings,
    DaliDevice,
    Datasheet,
    Element,
    ElementBindingStatus,
    HardwareRegistration,
    Riom,
    TerminalUnitModel,
    Topology,
    ZoneDetail,
} from '@priva/next-model';

import { ConfigurationApiPaths, DatasheetsApiPaths, NextApiPaths } from 'app/configuration';

@Injectable({
    providedIn: 'root',
})
export class NextService {
    private readonly httpClient = inject(HttpClient);
    private readonly nextApiPaths: NextApiPaths = inject(NextApiPaths);
    private readonly configurationApiPaths: ConfigurationApiPaths = inject(ConfigurationApiPaths);
    private readonly datasheetApiPaths: DatasheetsApiPaths = inject(DatasheetsApiPaths);

    /* istanbul ignore next wvk, just simple get */
    public getTerminalUnitModels(): Observable<TerminalUnitModel[]> {
        const url = `${this.nextApiPaths.terminalUnitModels()}`;
        const params = { latestOnly: true, includeControlApplicationTemplateDetails: false };
        return this.httpClient.get<TerminalUnitModel[]>(url, { params });
    }

    /* istanbul ignore next wvk, just simple get */
    public getTopology(solutionId: string): Observable<Topology> {
        const url = this.nextApiPaths.topology(solutionId);
        return this.httpClient.get<Topology>(url);
    }

    /* istanbul ignore next wvk, just simple get */
    public getZone(solutionId: string, zoneId: string): Observable<ZoneDetail> {
        const url = `${this.nextApiPaths.topology(solutionId)}/zones/${zoneId}`;
        return this.httpClient.get<ZoneDetail>(url);
    }

    /* istanbul ignore next wvk, just simple get */
    public getElements(solutionId: string): Observable<Element[]> {
        const url = this.nextApiPaths.elements(solutionId);
        return this.httpClient.get<Element[]>(url);
    }

    /* istanbul ignore next wvk, just simple get */
    public getElement(solutionId: string, elementId: string): Observable<Element> {
        const url = this.nextApiPaths.element(solutionId, elementId);
        return this.httpClient.get<Element>(url);
    }

    /* istanbul ignore next wvk, just simple get */
    public getAllConfigurations(solutionId: string): Observable<ControllerConfigurationInfo[]> {
        return this.httpClient.get<ControllerConfigurationInfo[]>(
            this.configurationApiPaths.controllersConfigurations(solutionId),
            { params: { latest: true } },
        );
    }

    /* istanbul ignore next wvk, just simple get */
    public getHardwareRegistrations(solutionId: string): Observable<HardwareRegistration[]> {
        const url = this.nextApiPaths.hardwareRegistrations(solutionId);
        return this.httpClient.get<HardwareRegistration[]>(url);
    }

    /* istanbul ignore next wvk, just simple get */
    public getControllerProperties(solutionId: string, elementId: string): Observable<ControllerProperties> {
        const url = this.nextApiPaths.controllerProperties(solutionId, elementId);
        return this.httpClient.get<ControllerProperties>(url);
    }

    /* istanbul ignore next bw, just simple get */
    public getControllersProperties(solutionId: string): Observable<ControllerProperties[]> {
        const url = this.nextApiPaths.controllersProperties(solutionId);
        return this.httpClient.get<ControllerProperties[]>(url);
    }

    /* istanbul ignore next bw, just simple get */
    public getElementsBindingStatus(solutionId: string, elementId: string): Observable<ElementBindingStatus> {
        const url = this.nextApiPaths.elementBindingStatus(solutionId, elementId);
        return this.httpClient.get<ElementBindingStatus>(url);
    }

    /* istanbul ignore next bw, just simple get */
    public getDaliDevices(solutionId: string): Observable<DaliDevice[]> {
        const url = this.nextApiPaths.daliDevices(solutionId);
        return this.httpClient.get<DaliDevice[]>(url);
    }

    /* istanbul ignore next bw, just simple get */
    public getAllDatasheets(): Observable<Datasheet[]> {
        const url = this.datasheetApiPaths.searchDatasheets();
        return this.httpClient.get<Datasheet[]>(url);
    }

    /* istanbul ignore next bw, just simple get */
    public getControllerProtocolSettings(
        solutionId: string,
        elementId: string,
    ): Observable<ControllerProtocolSettings> {
        const url = this.nextApiPaths.controllerProtocolSettings(solutionId, elementId);
        return this.httpClient.get<ControllerProtocolSettings>(url);
    }

    /* istanbul ignore next wvk, just simple get */
    public getRiom(solutionId: string, elementId): Observable<Riom> {
        const url = this.nextApiPaths.conventionalIoRiom(solutionId, elementId);
        return this.httpClient.get<Riom>(url);
    }
}
