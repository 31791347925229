import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { ControllerProperties } from '@priva/next-model';

import { NextLocalStorageService, RequestLocalStorageService } from 'app/common/local-storage';
import { X_HEADER } from 'app/core/http';
import { ControllersActions } from 'app/solution/controllers/state';
import { ElementsStateContainer } from 'app/solution/elements/state';
import { SolutionStateContainer } from 'app/solution/state';

import {
    extractControllerPropertiesElementIdFromNextApi,
    extractElementIdFromNextApi,
    extractSolutionIdFromNextApi,
} from '../offline-sync.helper';

@Injectable()
export class OfflineSyncControllerPropertiesInterceptor implements HttpInterceptor {
    private readonly localStorageService = inject(NextLocalStorageService);
    private readonly requestLocalStorageService = inject(RequestLocalStorageService);
    private readonly store$ = inject<Store<SolutionStateContainer & ElementsStateContainer>>(
        Store<SolutionStateContainer & ElementsStateContainer>,
    );

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) === ControllersActions.getControllerProperties.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            const elementId = extractControllerPropertiesElementIdFromNextApi(request.url);
            return this.store$.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(
                            this.localStorageService.getControllerProperties(solutionId, elementId),
                        ).pipe(
                            map(
                                (controllerProperties: ControllerProperties) =>
                                    new HttpResponse({ body: controllerProperties }),
                            ),
                        );
                    }
                    return next.handle(request);
                }),
            );
        }

        if (request.headers.get(X_HEADER) === ControllersActions.getAllControllerProperties.type) {
            {
                const solutionId = extractSolutionIdFromNextApi(request.url);
                return this.store$.pipe(
                    first(),
                    switchMap((state) => {
                        if (state.solution?.active?.offline) {
                            return from(this.localStorageService.getControllersProperties(solutionId)).pipe(
                                map(
                                    (controllersProperties: ControllerProperties[]) =>
                                        new HttpResponse({ body: controllersProperties }),
                                ),
                            );
                        } else {
                            return next.handle(request);
                        }
                    }),
                );
            }
        }

        if (request.headers.get(X_HEADER) === ControllersActions.updateControllerProperties.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            const elementId = extractElementIdFromNextApi(request.url);

            return this.store$.pipe(
                first(),
                switchMap((state) =>
                    state.solution?.active?.offline
                        ? from(this.requestLocalStorageService.add(solutionId, request)).pipe(
                              switchMap(() =>
                                  from(
                                      this.localStorageService.updateControllerProperties(
                                          solutionId,
                                          elementId,
                                          request.body as ControllerProperties,
                                      ),
                                  ).pipe(
                                      map(
                                          (controllerProperties: ControllerProperties) =>
                                              new HttpResponse({ body: controllerProperties }),
                                      ),
                                  ),
                              ),
                          )
                        : next.handle(request),
                ),
            );
        }
        return next.handle(request);
    }
}
