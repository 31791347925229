import { HttpClient, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, toArray } from 'rxjs/operators';

import { PrivaNotificationsService } from '@priva/components/notifications';
import { Solution } from '@priva/next-model';

import { NextLocalStorageService } from '../local-storage/next-local-storage.service';
import { RequestLocalStorageService } from '../local-storage/request-local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class OfflineSyncService {
    private readonly httpClient = inject(HttpClient);
    private readonly nextLocalStorageService = inject(NextLocalStorageService);
    private readonly privaNotificationsService = inject(PrivaNotificationsService);
    private readonly requestLocalStorageService = inject(RequestLocalStorageService);

    public async setAutoSync(solution?: Solution, autoSync = true): Promise<any> {
        const solutions: Solution[] = solution
            ? [solution]
            : await this.nextLocalStorageService.getSolutions();
        for (const solution of solutions) {
            await this.nextLocalStorageService.putSolution({ ...solution, autoSync });
        }
    }

    public getRequestCount(solution: Solution): Observable<number> {
        return this.requestLocalStorageService.getRequestCount(solution.id);
    }

    public getOfflineSolutions(): Observable<string[]> {
        return this.requestLocalStorageService.getRequests().pipe(
            map((reqs) => reqs.map((req) => req.solutionId)),
            map((solutionIds) => [...new Set(solutionIds)]),
        );
    }

    public flushRequests(solution: Solution): Observable<any> {
        return this.requestLocalStorageService.getRequests(solution.id).pipe(
            switchMap((reqs) => reqs),
            concatMap((req) => this.fireAndWait(req)),
            toArray(),
        );
    }

    private fireAndWait(requestRow: { id: number; request: HttpRequest<any> }): Observable<any> {
        const requestClone = new HttpRequest(
            requestRow.request.method,
            requestRow.request.url,
            requestRow.request.body,
        );
        return this.httpClient.request(requestClone).pipe(
            catchError((resp) => {
                this.showError(resp.error[0]);
                return of(resp);
            }),
            switchMap(() => this.requestLocalStorageService.remove(requestRow.id)),
        );
    }

    private showError(error: any): void {
        const errorMessage = error.id;
        this.privaNotificationsService.toaster.error(errorMessage);
    }
}
