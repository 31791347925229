import { inject, Injectable } from '@angular/core';
import { EMPTY, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, switchMap } from 'rxjs/operators';

import { Element, isController, isRiom, Solution, Topology, Zone } from '@priva/next-model';

import { flattenTopology } from '@next/design-system/topology';

import { ConfigurationLocalStorageService } from '../../local-storage/configuration-local-storage.service';
import { FloorplanLocalStorageService } from '../../local-storage/floorplan-local-storage.service';
import { NextLocalStorageService } from '../../local-storage/next-local-storage.service';
import { RequestLocalStorageService } from '../../local-storage/request-local-storage.service';
import { FloorplanService } from './floorplan.service';
import { NextService } from './next.service';

@Injectable({
    providedIn: 'root',
})
export class ScraperService {
    private readonly floorplanService = inject(FloorplanService);
    private readonly nextService = inject(NextService);
    private readonly configurationLocalStorageService = inject(ConfigurationLocalStorageService);
    private readonly nextLocalStorageService = inject(NextLocalStorageService);
    private readonly floorplanLocalStorageService = inject(FloorplanLocalStorageService);
    private readonly requestLocalStorageService = inject(RequestLocalStorageService);

    public scrape(solution: Solution): Observable<any> {
        return this.scrapeNextModel(solution).pipe(
            switchMap((topology) => {
                const siteId = topology?.roots[0]?.id;
                if (siteId) {
                    return this.scrapeFloorplanModel(solution, siteId);
                }
                return EMPTY;
            }),
        );
    }

    public clear(solution?: Solution): Observable<any> {
        const solutions = (
            solution ? of([solution]) : from(this.nextLocalStorageService.getSolutions())
        ).pipe(switchMap((s) => s));

        return solutions.pipe(
            switchMap((s) =>
                Promise.all([
                    this.nextLocalStorageService.clear(s),
                    this.floorplanLocalStorageService.clear(s.id),
                    this.nextLocalStorageService.clear(s),
                    this.requestLocalStorageService.clear(s.id),
                ]),
            ),
        );
    }

    private scrapeNextModel(solution: Solution): Observable<Topology> {
        return forkJoin([
            this.nextService.getTopology(solution.id),
            this.nextService.getElements(solution.id),
            this.nextService.getTerminalUnitModels(),
            this.nextService.getAllConfigurations(solution.id),
            this.nextService.getHardwareRegistrations(solution.id),
            this.nextService.getDaliDevices(solution.id),
            this.nextService.getControllersProperties(solution.id), // NOTE (BW): not exactly the same as controlle<r>Properties => this one's dedicated per solution
            this.nextService.getAllDatasheets(),
        ]).pipe(
            map(
                ([
                    topology,
                    elements,
                    models,
                    configuration,
                    hardwareRegistrations,
                    daliDevices,
                    controllersProperties,
                    datasheets,
                ]) => ({
                    topology,
                    elements,
                    models,
                    configuration,
                    hardwareRegistrations,
                    daliDevices,
                    controllersProperties,
                    datasheets,
                }),
            ),
            switchMap((results) =>
                forkJoin(
                    results.elements.map((d: Element) => this.nextService.getElement(solution.id, d.id)),
                ).pipe(
                    defaultIfEmpty([]),
                    map((elements) => ({ ...results, elements })),
                ),
            ),
            switchMap((results) =>
                forkJoin(
                    results.elements.map((d: Element) =>
                        this.nextService.getElementsBindingStatus(solution.id, d.id),
                    ),
                ).pipe(
                    defaultIfEmpty([]),
                    map((elementBindingStatus) => ({ ...results, elementBindingStatus })),
                ),
            ),
            switchMap((results) => {
                const controllers = results.elements.filter(isController) ?? [];
                return forkJoin(
                    controllers.map((c: Element) =>
                        this.nextService.getControllerProperties(solution.id, c.id),
                    ),
                ).pipe(
                    defaultIfEmpty([]),
                    map((controllerProperties) => ({ ...results, controllerProperties })),
                );
            }),
            switchMap((results) => {
                const controllers = results.elements.filter(isController) ?? [];
                return forkJoin(
                    controllers.map((c: Element) =>
                        this.nextService.getControllerProtocolSettings(solution.id, c.id),
                    ),
                ).pipe(
                    catchError(() => of([])),
                    defaultIfEmpty([]),
                    map((controllerProtocolSettings) => ({ ...results, controllerProtocolSettings })),
                );
            }),
            switchMap((results) =>
                forkJoin(
                    Array.from(flattenTopology(results.topology)).map((z: Zone) =>
                        this.nextService.getZone(solution.id, z.id),
                    ),
                ).pipe(
                    defaultIfEmpty([]),
                    map((zones) => ({ ...results, zones })),
                ),
            ),
            switchMap((results) => {
                const riomElements = results.elements.filter(isRiom);
                return forkJoin(
                    riomElements.map((riom) => this.nextService.getRiom(solution.id, riom.id)),
                ).pipe(
                    defaultIfEmpty([]),
                    map((rioms) => ({ ...results, rioms })),
                );
            }),
            switchMap((results) =>
                forkJoin([
                    this.nextLocalStorageService.putSolution(solution),
                    this.nextLocalStorageService.putTopology(solution.id, results.topology),
                    this.nextLocalStorageService.putZones(solution.id, results.zones),
                    this.nextLocalStorageService.putElements(solution.id, results.elements),
                    this.nextLocalStorageService.putHardwareRegistrations(
                        solution.id,
                        results.hardwareRegistrations,
                    ),
                    this.nextLocalStorageService.putControllerProperties(
                        solution.id,
                        results.controllerProperties,
                    ),
                    this.nextLocalStorageService.putElementBindingStatus(
                        solution.id,
                        results.elementBindingStatus,
                    ),
                    this.nextLocalStorageService.putDaliDevices(solution.id, results.daliDevices),
                    this.nextLocalStorageService.putDatasheets(solution.id, results.datasheets),
                    this.nextLocalStorageService.putControllerProtocolSettings(
                        solution.id,
                        results.controllerProtocolSettings,
                    ),
                    this.nextLocalStorageService.putModels(results.models),
                    this.nextLocalStorageService.putRioms(solution.id, results.rioms),
                    this.configurationLocalStorageService.put(solution.id, results.configuration),
                ]).pipe(map(() => results.topology)),
            ),
        );
    }

    private scrapeFloorplanModel(solution: Solution, siteId: string): Observable<void> {
        return forkJoin([
            this.persistZone(solution, siteId),
            this.persistZoneZones(solution, siteId),
            this.persistElements(solution, siteId),
        ]).pipe(map(() => undefined));
    }

    private persistZone(solution: Solution, siteId: string) {
        return this.floorplanService
            .getZone(solution.id, siteId)
            .pipe(switchMap((zone) => this.floorplanLocalStorageService.putZone(solution.id, zone)));
    }

    private persistZoneZones(solution: Solution, siteId: string) {
        return this.floorplanService.getZoneZones(solution.id, siteId).pipe(
            switchMap((zoneZones) =>
                from(this.floorplanLocalStorageService.putZones(solution.id, siteId, zoneZones)).pipe(
                    map(() => zoneZones),
                ),
            ),
            map((zoneZones) => zoneZones.features.filter((f) => f?.properties?.imageUrl)),
            switchMap((zoneZones) =>
                forkJoin(this.persistImages(zoneZones, solution)).pipe(defaultIfEmpty([])),
            ),
        );
    }

    private persistImages(zoneZones, solution: Solution) {
        return zoneZones.map((f) =>
            this.floorplanService
                .getImage(f.properties.imageUrl)
                .pipe(
                    switchMap((image) =>
                        this.floorplanLocalStorageService.putImage(
                            solution.id,
                            f?.properties?.imageUrl.split('?')[0],
                            image,
                        ),
                    ),
                ),
        );
    }

    private persistElements(solution: Solution, siteId: string) {
        return this.floorplanService
            .getZoneElements(solution.id, siteId)
            .pipe(
                switchMap((elements) => this.floorplanLocalStorageService.putElements(solution.id, elements)),
            );
    }
}
