import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { Topology, ZoneDetail } from '@priva/next-model';

import { X_HEADER } from 'app/core/http';
import { SolutionState } from 'app/solution/state';
import { TopologyApiActions } from 'app/solution/topology/state';
import { ZoneActions } from 'app/solution/zone/state';

import { NextLocalStorageService } from '../../local-storage/next-local-storage.service';
import { extractSolutionIdFromNextApi, extractZoneIdFromNextApi } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncTopologyInterceptor implements HttpInterceptor {
    private localStorageService = inject(NextLocalStorageService);
    private readonly store = inject<Store<{ solution: SolutionState }>>(Store<{ solution: SolutionState }>);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) === TopologyApiActions.getTopology.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            return this.store.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(this.localStorageService.getTopology(solutionId)).pipe(
                            map(
                                (topology: Topology) =>
                                    new HttpResponse({ body: { ...topology, id: undefined } }),
                            ),
                        );
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }

        if (request.headers.get(X_HEADER) === ZoneActions.getZone.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            const zoneId = extractZoneIdFromNextApi(request.url);

            return this.store.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(this.localStorageService.getZone(solutionId, zoneId)).pipe(
                            map((zone: ZoneDetail) => new HttpResponse({ body: { ...zone } })),
                        );
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }

        return next.handle(request);
    }
}
