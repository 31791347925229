import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { ElementBindingStatus } from '@priva/next-model';

import { X_HEADER } from 'app/core/http';
import { ElementBindingActions } from 'app/solution/element/state/element-binding';
import { SolutionStateContainer } from 'app/solution/state';

import { NextLocalStorageService } from '../../local-storage/next-local-storage.service';
import { extractElementIdFromNextApi, extractSolutionIdFromNextApi } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncElementBindingStatusInterceptor implements HttpInterceptor {
    private readonly localStorageService = inject(NextLocalStorageService);
    private readonly store$ = inject<Store<SolutionStateContainer>>(Store<SolutionStateContainer>);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) === ElementBindingActions.getElementBindingStatus.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            const elementId = extractElementIdFromNextApi(request.url);
            return this.store$.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(
                            this.localStorageService.getElementBindingStatus(solutionId, elementId),
                        ).pipe(
                            map(
                                (elementBindingStatus: ElementBindingStatus) =>
                                    new HttpResponse({ body: elementBindingStatus }),
                            ),
                        );
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }
        return next.handle(request);
    }
}
