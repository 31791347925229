import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { ControllerConfigurationInfo } from '@priva/next-model';

import { X_HEADER } from 'app/core/http';
import { CxssConfigurationActions } from 'app/solution/controllers/state/configuration';
import { SolutionState } from 'app/solution/state';

import { ConfigurationLocalStorageService } from '../../local-storage/configuration-local-storage.service';
import { extractSolutionIdFromNextApi } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncConfigurationInterceptor implements HttpInterceptor {
    private configurationLocalStorageService = inject(ConfigurationLocalStorageService);
    private readonly store = inject<Store<{ solution: SolutionState }>>(Store<{ solution: SolutionState }>);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (
            request.headers.get(X_HEADER) ===
            CxssConfigurationActions.getAllLatestGeneratedConfigurations.type
        ) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            return this.store.pipe(
                first(),
                switchMap((state) =>
                    state.solution?.active?.offline ? this.handleOffline(solutionId) : next.handle(request),
                ),
            );
        }

        return next.handle(request);
    }

    private handleOffline(solutionId: string): Observable<HttpResponse<object>> {
        return from(this.configurationLocalStorageService.getLatest(solutionId)).pipe(
            map(
                (configurations: ControllerConfigurationInfo[]) => new HttpResponse({ body: configurations }),
            ),
        );
    }
}
